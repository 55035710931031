import { event } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { ActionIcon } from 'app/components/ActionIcon'
import { Dropdown } from 'app/components/Dropdown'
import { showInviteDialog } from 'app/features/ecommerce/InviteDialog/InviteDialog'
import { DiscordLink } from 'app/features/socialMedia/DiscordLink/DiscordLink'
import { FacebookLink } from 'app/features/socialMedia/FacebookLink/FacebookLink'
import { TwitterLink } from 'app/features/socialMedia/TwitterLink/TwitterLink'
import { IconCategory } from 'app/icons/IconCategory'
import { IconChat } from 'app/icons/IconChat'
import { IconCup } from 'app/icons/IconCup'
import { IconDollar } from 'app/icons/IconDollar'
import { IconExplore } from 'app/icons/IconExplore'
import { IconFullName } from 'app/icons/IconFullName'
import { IconGenerate } from 'app/icons/IconGenerate'
import { IconMail } from 'app/icons/IconMail'
import { IconQuestion } from 'app/icons/IconQuestion'
import { IconShortName } from 'app/icons/IconShortName'
import { IconUser } from 'app/icons/IconUser'
import { getExploreModelLink, getExplorePostLink } from 'app/utils/model-version'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronDown } from 'lucide-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WEB_HEADER_HEIGHT } from '../constants/layoutToken'
import { showContactUsModal } from './ContactUsModal/ConatctUsModal'

interface MenuItem {
  title: string
  icon?: React.ElementType
  subItems?: { title: string; path?: string; onClick?: () => void; isNew?: boolean }[]
  path?: string
  isNew?: boolean
}

const useMenuItems = () => {
  const { t } = useTranslation('home', { keyPrefix: 'sidebar' })
  return [
    {
      title: t('explore'),
      icon: IconExplore,
      isNew: true,
      subItems: [
        { title: t('images'), path: getExplorePostLink() },
        { title: t('models'), path: getExploreModelLink() },
      ],
    },
    {
      title: t('generate'),
      icon: IconGenerate,
      subItems: [
        { title: t('generateImage'), path: '/model/play' },
        { title: t('generateModel'), path: '/model/create' },
        { title: t('makePersona'), path: '/model/make' },
        {
          title: t('imageEditor'),
          path: '/image-editor',
        },
      ],
    },
    {
      title: t('chat'),
      icon: IconChat,
      path: '/chat',
    },
    {
      title: 'Credits',
      icon: IconDollar,
      subItems: [
        { title: t('membership'), path: '/billings' },
        { title: t('buyCredits'), path: '/billings' },
        { title: t('freeCredits'), onClick: () => showInviteDialog() },
      ],
    },
    {
      title: t('account'),
      icon: IconUser,
      subItems: [
        { title: t('myCreations'), path: '/profile' },
        { title: t('settings'), path: '/settings' },
      ],
    },
  ] as MenuItem[]
}

const onClose$ = event()

export const SidebarMenu = observer(
  ({
    isCompact,
    showLogo,
    onItemClick,
  }: {
    isCompact: boolean
    showLogo: boolean
    onItemClick?: (path: string) => void
  }) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([])
    const router = useRouter()
    const menuItems = useMenuItems()

    const toggleExpand = (title: string) => {
      setExpandedItems((prev) =>
        prev.includes(title) ? prev.filter((item) => item !== title) : [...prev, title]
      )
    }

    const onItemClickHandler = (item: NonNullable<MenuItem['subItems']>[number]) => {
      if (item.path) {
        router.push(item.path)
      } else {
        item.onClick?.()
      }
      onItemClick?.(item.path ?? '')
    }

    useEffect(() => {
      const unsub = onClose$.on(() => {
        onItemClick?.('')
      })
      return () => unsub()
    }, [onItemClick])

    return (
      <nav className={`bg-coal-600 relative flex h-full w-full flex-col shadow-lg transition-all`}>
        <div className={cn('flex flex-1 flex-col', !isCompact && 'overflow-y-hidden')}>
          {showLogo && (
            <div
              className="border-coal-100 flex cursor-pointer items-center justify-center overflow-hidden border-b-2 border-solid px-4 text-lg font-semibold text-gray-900"
              style={{ height: WEB_HEADER_HEIGHT }}
              onClick={() => {
                router.push('/')
              }}
            >
              {isCompact ? (
                <IconShortName className="mt-2 w-6" />
              ) : (
                <IconFullName className="mt-2 w-40" />
              )}
            </div>
          )}
          <div className={cn('flex-1 p-4', !isCompact && 'custom-scrollbar overflow-y-auto')}>
            {menuItems.map((item) => (
              <div key={item.title} className="relative w-full">
                {!isCompact && (
                  <div
                    className={`my-1 flex w-full cursor-pointer items-center justify-between rounded-lg p-2 text-gray-600 transition-colors hover:text-gray-900 sm:text-lg`}
                    onClick={() => {
                      if (!isCompact && item.subItems) {
                        toggleExpand(item.title)
                      } else if (item.path) {
                        onItemClickHandler(item)
                      }
                    }}
                  >
                    <div className="flex items-center gap-2">
                      {item.icon && <item.icon className="h-5 w-5" />}
                      <span className="ml-2">{item.title}</span>
                      <div>{item.isNew && <NewTag />}</div>
                    </div>
                    {item.subItems && (
                      <motion.div
                        animate={{ rotate: expandedItems.includes(item.title) ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <ChevronDown className="h-4 w-4" />
                      </motion.div>
                    )}
                  </div>
                )}

                {isCompact ? (
                  <Dropdown
                    trigger={
                      <div className="hover:bg-coal-300 my-2 flex w-full cursor-pointer items-center justify-center rounded-lg p-2 text-gray-600 transition-colors hover:text-gray-900">
                        {item.icon && <item.icon className="h-5 w-5" />}
                      </div>
                    }
                    placement="right-start"
                  >
                    <div className="bg-coal-100 min-w-[220px] rounded-sm p-4">
                      {item.subItems ? (
                        <>
                          <div className="mb-2 flex items-center gap-2 px-3 text-lg font-medium">
                            {item.title}
                            <div>{item.isNew && <NewTag />}</div>
                          </div>
                          {item.subItems.map((subItem) => (
                            <div
                              key={subItem.title}
                              className={cn(
                                'group flex cursor-pointer items-center rounded-lg px-3 py-2 text-gray-600 transition-colors hover:text-gray-900',
                                router.pathname === subItem.path &&
                                  'text-gray-900 hover:text-gray-900'
                              )}
                              onClick={(e) => {
                                e.stopPropagation()
                                onItemClickHandler(subItem)
                              }}
                            >
                              <span
                                className={cn(
                                  'mr-2 h-1.5 w-1.5 rounded-full bg-gray-600 transition-colors group-hover:bg-gray-900',
                                  router.pathname === subItem.path && 'bg-gray-900'
                                )}
                              />
                              {subItem.title}
                              {subItem?.isNew && <NewTag />}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div
                          className={cn(
                            'cursor-pointer rounded-lg px-3 py-2 text-gray-600 transition-colors hover:text-gray-900',
                            router.pathname === item.path && 'text-gray-900 hover:text-gray-900'
                          )}
                          onClick={() => {
                            onItemClickHandler(item)
                          }}
                        >
                          {item.title}
                        </div>
                      )}
                    </div>
                  </Dropdown>
                ) : (
                  <AnimatePresence>
                    {expandedItems.includes(item.title) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="overflow-hidden"
                      >
                        {item.subItems?.map((subItem) => (
                          <div
                            key={subItem.title}
                            className={cn(
                              'relative cursor-pointer rounded-lg py-2 pl-11 text-sm text-gray-600 transition-colors hover:text-gray-900 sm:text-base',
                              router.pathname === subItem.path &&
                                'text-gray-900 hover:text-gray-900'
                            )}
                            onClick={() => {
                              onItemClickHandler(subItem)
                            }}
                          >
                            {subItem.title}
                            {subItem?.isNew && <NewTag />}
                          </div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="border-coal-100 w-full border-t-2">
          {!isCompact ? (
            <SidebarMenuBottom />
          ) : (
            <Dropdown
              trigger={
                <div className="hover:bg-coal-300 my-2 flex w-full cursor-pointer items-center justify-center rounded-lg p-2 pb-8 text-gray-600 transition-colors hover:text-gray-900">
                  <ActionIcon icon={<IconCategory className="h-5 w-5" />} />
                </div>
              }
              placement="right-end"
            >
              <div className="bg-coal-100 min-w-[300px] rounded-sm p-4">
                <SidebarMenuBottom />
              </div>
            </Dropdown>
          )}
        </div>
      </nav>
    )
  }
)

const useBottomItems = () => {
  const { t } = useTranslation('home', { keyPrefix: 'sidebar' })

  return [
    { icon: IconMail, title: t('contact'), onClick: () => showContactUsModal() },
    {
      icon: IconQuestion,
      title: t('helpDocs'),
      path: 'https://docs.mydreamboy.com',
    },
    { icon: IconCup, title: t('affiliate'), path: '/affiliate' },
  ]
}

const SidebarMenuBottom = observer(() => {
  const BottomItems = useBottomItems()
  return (
    <div className="flex w-full flex-col gap-2 px-6 py-4 text-xs text-gray-600 md:text-sm">
      {BottomItems.map((item) => (
        <BottomLink key={item.title} path={item.path} onClick={item.onClick}>
          <div className="flex w-full cursor-pointer items-center justify-center gap-4 rounded-lg bg-gray-100 p-2 opacity-70 transition-colors hover:text-gray-900 hover:opacity-100">
            <item.icon className="h-5 w-5" />
            <p>{item.title}</p>
          </div>
        </BottomLink>
      ))}
      <div className="-mt-2">
        <div className="flex scale-75 items-center justify-center gap-4 opacity-50 transition-opacity hover:opacity-100">
          <TwitterLink />
          <DiscordLink />
          <FacebookLink />
        </div>
        <div className="flex flex-col items-center justify-center gap-1 text-xs text-gray-300">
          <Link href="/privacy-policy" style={{ lineHeight: 1 }} onClick={() => onClose$.fire()}>
            <p className="hover:text-gray-900">Privacy Policy</p>
          </Link>
          <Link href="/terms-of-service" style={{ lineHeight: 1 }} onClick={() => onClose$.fire()}>
            <p className="hover:text-gray-900">Terms of Service</p>
          </Link>
        </div>
      </div>
    </div>
  )
})

const BottomLink = observer(
  ({
    path,
    onClick,
    children,
  }: {
    path?: string
    onClick?: () => void
    children: React.ReactNode
  }) => {
    if (path) {
      return (
        <Link href={path} target="_blank" onClick={() => onClose$.fire()}>
          {children}
        </Link>
      )
    }
    return (
      <div
        onClick={() => {
          onClick?.()
          onClose$.fire()
        }}
      >
        {children}
      </div>
    )
  }
)

const NewTag = () => {
  return (
    <span className="bg-danger ml-2 rounded-sm px-1.5 py-0.5 text-[10px] font-medium text-white">
      NEW
    </span>
  )
}
