import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 23a11 11 0 1 1 11-11 11.01 11.01 0 0 1-11 11Zm0-20.465A9.465 9.465 0 1 0 21.465 12 9.476 9.476 0 0 0 12 2.535Zm-1.023 15.983a1.277 1.277 0 0 1-.44-.072 1.259 1.259 0 0 1-.83-1.176v-3.745h-1.35a1.259 1.259 0 0 1-.941-2.047l4.758-5.525a1.268 1.268 0 0 1 2.22.839v3.806h1.28a1.26 1.26 0 0 1 .951 2.047l-4.748 5.495a1.248 1.248 0 0 1-.9.378ZM8.93 12h2.21v4.523l3.93-4.43h-2.262V7.476L8.93 12Z"
    />
  </svg>
)
export const IconExplore = memo(SvgComponent)
