import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M15.15 11.85a4.79 4.79 0 0 0-1.24-.62c-.35-.12-.74-.23-1.15-.34V8.17c.13.043.251.107.36.19.295.263.533.583.7.94.109.22.247.426.41.61a.81.81 0 0 0 .57.18.88.88 0 0 0 .68-.3 1 1 0 0 0 .27-.7 1.859 1.859 0 0 0-.22-.86 2.54 2.54 0 0 0-.69-.85 3.47 3.47 0 0 0-1.19-.65 4.39 4.39 0 0 0-.86-.23V6a.75.75 0 0 0-.75-.76.74.74 0 0 0-.75.74v.52a5.24 5.24 0 0 0-1.45.31 3 3 0 0 0-1.39 1.1A2.62 2.62 0 0 0 8 9.5a2.55 2.55 0 0 0 .45 1.54 3 3 0 0 0 1.23 1 9.083 9.083 0 0 0 1.6.53v3.31a2.089 2.089 0 0 1-.4-.13 1.8 1.8 0 0 1-.67-.57 5.118 5.118 0 0 1-.44-.85 1.68 1.68 0 0 0-.4-.65.84.84 0 0 0-.58-.21.9.9 0 0 0-.69.28.89.89 0 0 0-.28.67c.02.497.179.98.46 1.39.284.45.667.83 1.12 1.11a4.64 4.64 0 0 0 1.81.55v.49a.75.75 0 0 0 1.036.707.739.739 0 0 0 .464-.687v-.48a5.32 5.32 0 0 0 1.57-.37 3.33 3.33 0 0 0 1.46-1.21 3.16 3.16 0 0 0 .5-1.74 2.94 2.94 0 0 0-.24-1.41 2.53 2.53 0 0 0-.85-.92Zm-3.91-1.35a4.096 4.096 0 0 1-.62-.26 1.76 1.76 0 0 1-.48-.4 1 1 0 0 1-.14-.57 1.059 1.059 0 0 1 .51-.88 2 2 0 0 1 .8-.3v2.4l-.07.01Zm2.27 5a2.27 2.27 0 0 1-.79.35v-2.89l.21.06a2 2 0 0 1 .83.5 1.17 1.17 0 0 1 .33.86 1.35 1.35 0 0 1-.58 1.12ZM12 1.25A10.75 10.75 0 1 0 22.75 12 10.76 10.76 0 0 0 12 1.25Zm0 20A9.25 9.25 0 1 1 21.25 12 9.26 9.26 0 0 1 12 21.25Z"
    />
  </svg>
)
export const IconDollar = memo(SvgComponent)
