import { useIsClient } from 'app/hooks/useIsClient'
import { motion, AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'

interface DrawerProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  direction?: 'left' | 'right' | 'top' | 'bottom'
  className?: string
}

const slideVariants = {
  left: {
    open: { x: 0 },
    closed: { x: '-100%' },
  },
  right: {
    open: { x: 0 },
    closed: { x: '100%' },
  },
  top: {
    open: { y: 0 },
    closed: { y: '-100%' },
  },
  bottom: {
    open: { y: 0 },
    closed: { y: '100%' },
  },
}

export function Drawer({
  isOpen,
  onClose,
  children,
  direction = 'right',
  className = '',
}: DrawerProps) {
  const isClient = useIsClient()

  // 添加视口高度更新逻辑
  useEffect(() => {
    if (!isClient) return

    const updateHeight = () => {
      // 设置一个自定义的视口高度 CSS 变量
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)

    return () => window.removeEventListener('resize', updateHeight)
  }, [isClient])

  // 控制页面滚动
  useEffect(() => {
    if (!isClient) return
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen, isClient])

  const isHorizontal = direction === 'left' || direction === 'right'

  if (!isClient) return null

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <>
          {/* 遮罩层 */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 z-[999] bg-black"
          />

          {/* Drawer 内容 */}
          <motion.div
            initial={slideVariants[direction].closed}
            animate={slideVariants[direction].open}
            exit={slideVariants[direction].closed}
            transition={{ type: 'tween', duration: 0.3 }}
            style={{
              position: 'fixed',
              [direction]: 0,
              top: direction === 'bottom' ? 'auto' : 0,
              bottom: direction === 'top' ? 'auto' : 0,
              height: isHorizontal ? 'calc(var(--vh, 1vh) * 100)' : undefined,
            }}
            className={`z-[1000] shadow-lg ${isHorizontal ? '' : 'w-screen'} ${className}`}
          >
            {children}
          </motion.div>
        </>
      )}
    </AnimatePresence>,
    isClient ? document.body : document.createElement('div')
  )
}
