import { Show, observer } from '@legendapp/state/react'
import { useRouter as useNextRouter } from 'next/router'
import { useEffect } from 'react'

import { MobileBottomTab } from './MobileBottomTab/MobileBottomtab'
import { homeLayout$ } from '../../stores/homeLayout'

const needHidePath = [
  'chat',
  'settings',
  'model/',
  'create-tag',
  'user',
  'billings',
  'search',
  'image-editor',
]

export const MobileBottombar = observer(() => {
  const router = useNextRouter()

  useEffect(() => {
    if (needHidePath.some((path) => router.pathname.includes(path))) {
      homeLayout$.mobileTabShown.set(false)
    } else {
      homeLayout$.mobileTabShown.set(true)
    }
  }, [router.pathname])

  return <Show if={homeLayout$.mobileTabShown}>{() => <MobileBottomTab />}</Show>
})
