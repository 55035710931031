import { observer } from '@legendapp/state/react'
import { useIsXlScreen } from 'app/hooks/useMediaQuery'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { SidebarMenu } from './SidebarMenu'
import { homeLayout$ } from '../../stores/homeLayout'
import { SIDEBAR_COMPACT_WIDTH, SIDEBAR_WIDTH } from '../constants/layoutToken'

const needCompactMenu = ['/chat']

export const WebSidebarMenu = observer(() => {
  const isXlScreen = useIsXlScreen()
  const [isCompact, setIsCompact] = useState(!isXlScreen)
  const router = useRouter()

  useEffect(() => {
    if (needCompactMenu.some((prefix) => router.pathname.startsWith(prefix))) {
      setIsCompact(true)
      homeLayout$.webSidebarWidth.set(SIDEBAR_COMPACT_WIDTH)
    } else {
      setIsCompact(!isXlScreen)
      homeLayout$.webSidebarWidth.set(isXlScreen ? SIDEBAR_WIDTH : SIDEBAR_COMPACT_WIDTH)
    }
  }, [isXlScreen, router.pathname])

  return (
    <div
      className="border-coal-100 transition-width fixed left-0 top-0 z-50 h-full border-r-2 border-solid duration-200"
      style={{ width: isCompact ? SIDEBAR_COMPACT_WIDTH : SIDEBAR_WIDTH }}
    >
      <SidebarMenu isCompact={isCompact} showLogo={true} />
    </div>
  )
})
