import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 23a11 11 0 1 1 11-11 11.01 11.01 0 0 1-11 11Zm0-20.465A9.465 9.465 0 1 0 21.465 12 9.476 9.476 0 0 0 12 2.535Zm0 12.534a.767.767 0 0 1-.767-.767v-.839a2.732 2.732 0 0 1 1.494-2.486 1.669 1.669 0 1 0-2.395-1.505.768.768 0 1 1-1.535 0 3.213 3.213 0 0 1 3.776-3.151 3.223 3.223 0 0 1 2.579 2.609 3.182 3.182 0 0 1-1.76 3.459 1.196 1.196 0 0 0-.624 1.084v.84a.767.767 0 0 1-.768.756Zm.767 1.883a.767.767 0 1 1-1.535 0 .767.767 0 0 1 1.535 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export const IconQuestion = memo(SvgComponent)
