import { ActionIcon } from 'app/components/ActionIcon'
import { IconMagnifier } from 'app/icons/IconMagnifier'
import { useRouter } from 'next/router'
export const SearchButton = () => {
  const router = useRouter()
  return (
    <ActionIcon
      icon={<IconMagnifier className="h-5 w-5" />}
      rounded
      className="box-border flex items-center justify-center rounded-full hover:bg-gray-200 md:p-3"
      onClick={() => router.push('/search')}
    />
  )
}
