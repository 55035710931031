import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { Dropdown } from 'app/components/Dropdown'
import { SignInButton } from 'app/features/auth/SignInButton/SignInButton'
import { UserTierText } from 'app/features/ecommerce/UserTierText/UserTierText'
import { useLanguage } from 'app/features/settings/hooks/useLanguage'
import { UserAvatar } from 'app/features/users/UserAvatar/UserAvatar'
import { useUserCredits } from 'app/features/users/hooks/useUserCredits'
import { useSupabase } from 'app/utils/supabase/useSupabase'
import { useUser } from 'app/utils/useUser'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

const useMenuItems = () => {
  const { t } = useTranslation('home', { keyPrefix: 'userMenu' })
  const { switchLanguage } = useLanguage()
  const supabase = useSupabase()
  const { isLogin } = useUser()

  return [
    [
      {
        label: t('profile'),
        path: '/settings',
      },
      {
        label: t('myCreations'),
        path: '/profile',
      },
      {
        label: t('myMemberships'),
        path: '/billings',
      },
    ],
    [
      {
        label: t('switchLanguage'),
        onClick: switchLanguage,
      },
      {
        label: t('accountSettings'),
        path: '/settings/general',
      },
      isLogin && {
        label: t('logout'),
        onClick: () => {
          supabase.auth.signOut()
        },
      },
    ],
  ]
}

export const UserMenu = observer(() => {
  const { profile$, isLogin } = useUser()
  const { creditsLeft$ } = useUserCredits()
  const router = useRouter()
  const menuItems = useMenuItems()
  return (
    <Dropdown
      trigger={
        <div className="flex items-center rounded-full border-2 border-gray-200">
          <UserAvatar size="$3" $gtSm={{ size: '$4' }} useSelfInfo />
        </div>
      }
      placement="bottom-left-start"
    >
      <div className="bg-coal-500 flex w-64 flex-col gap-2 rounded-sm py-4">
        <div className="flex items-center gap-2 border-b border-gray-200 px-4 pb-4">
          {isLogin ? (
            <>
              <UserAvatar size="$4" useSelfInfo />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2">
                  <p className="max-w-24 truncate text-sm font-semibold">
                    {profile$.name.get() ?? 'Guest'}
                  </p>
                  <p className="text-primary bg-primary-light rounded-lg px-2 py-1 text-xs font-semibold">
                    <UserTierText />
                  </p>
                </div>
                <p className="text-xs text-gray-800">{creditsLeft$.get()} credits left</p>
              </div>
            </>
          ) : (
            <>
              <SignInButton />
            </>
          )}
        </div>
        {menuItems.map((group, index) => (
          <div
            key={index}
            className={cn(
              'flex flex-col gap-4 px-6 py-4 text-sm text-gray-600',
              index === 0 && 'border-b'
            )}
          >
            {group.map((item) =>
              item ? (
                <div
                  key={item.label}
                  className="cursor-pointer hover:text-gray-900"
                  onClick={() => {
                    if (item.path) {
                      router.push(item.path)
                    } else {
                      item.onClick?.()
                    }
                  }}
                >
                  {item.label}
                </div>
              ) : null
            )}
          </div>
        ))}
      </div>
    </Dropdown>
  )
})
