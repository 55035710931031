import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M22.8 16.06V7.98a6.71 6.71 0 0 0-6.71-6.67H7.95A6.71 6.71 0 0 0 1.2 8.06v8.08a6.71 6.71 0 0 0 4.57 6.3h.07l.59.16h.09c.18 0 .37.06.56.09h9.9c.171-.017.341-.043.51-.08h.09l.59-.16c.22-.07.44-.16.65-.25l.63-.28a7.169 7.169 0 0 0 1.43-1.1 6.69 6.69 0 0 0 1.92-4.76ZM7.85 21.32a3.85 3.85 0 0 1-.76-.08 2.205 2.205 0 0 1-.37-.07l-.23-.11a6.14 6.14 0 0 1 11 0l-.22.07-.25.05a4.428 4.428 0 0 1-.9.1L8 21.33l-.15-.01Zm11-.84a7.6 7.6 0 0 0-13.71 0 5.2 5.2 0 0 1-2.4-4.34L2.7 8.06a5.22 5.22 0 0 1 5.18-5.23h8.14a5.22 5.22 0 0 1 5.21 5.18v8.08a5.157 5.157 0 0 1-1.51 3.69 4.79 4.79 0 0 1-.87.7ZM12 6.22a4.37 4.37 0 1 0 0 8.74 4.37 4.37 0 0 0 0-8.74Zm0 7.24a2.87 2.87 0 1 1 2.87-2.87A2.88 2.88 0 0 1 12 13.46Z"
    />
  </svg>
)
export const IconUser = memo(SvgComponent)
