import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M8.028 11.328H5.33A3.34 3.34 0 0 1 2 7.998V5.29A3.33 3.33 0 0 1 5.33 1.97h2.698a3.33 3.33 0 0 1 3.33 3.32v2.708a3.34 3.34 0 0 1-3.33 3.33ZM5.33 3.365a1.935 1.935 0 0 0-1.935 1.926v2.707A1.935 1.935 0 0 0 5.33 9.933h2.698a1.935 1.935 0 0 0 1.935-1.935V5.29a1.935 1.935 0 0 0-1.935-1.926H5.33Zm13.34 7.991h-2.698a3.33 3.33 0 0 1-3.33-3.33V5.328a3.34 3.34 0 0 1 3.33-3.33h2.698A3.34 3.34 0 0 1 22 5.328v2.698a3.33 3.33 0 0 1-3.33 3.33Zm-2.698-7.963a1.935 1.935 0 0 0-1.935 1.935v2.698a1.935 1.935 0 0 0 1.935 1.935h2.698a1.935 1.935 0 0 0 1.935-1.935V5.328a1.935 1.935 0 0 0-1.935-1.935h-2.698ZM8.028 21.998H5.33A3.34 3.34 0 0 1 2 18.668V15.97a3.33 3.33 0 0 1 3.33-3.33h2.698a3.33 3.33 0 0 1 3.33 3.33v2.698a3.34 3.34 0 0 1-3.33 3.311v.019ZM5.33 14.035a1.935 1.935 0 0 0-1.935 1.935v2.698a1.935 1.935 0 0 0 1.935 1.934h2.698a1.935 1.935 0 0 0 1.935-1.934V15.97a1.935 1.935 0 0 0-1.935-1.935H5.33Zm13.34 7.99h-2.698a3.33 3.33 0 0 1-3.33-3.32v-2.726a3.34 3.34 0 0 1 3.33-3.33h2.698a3.34 3.34 0 0 1 3.33 3.33v2.707a3.33 3.33 0 0 1-3.33 3.321v.019Zm-2.698-7.962a1.935 1.935 0 0 0-1.935 1.935v2.688a1.935 1.935 0 0 0 1.935 1.926h2.698a1.935 1.935 0 0 0 1.935-1.926V15.98a1.935 1.935 0 0 0-1.935-1.935l-2.698.019Z"
    />
  </svg>
)
export const IconCategory = memo(SvgComponent)
