import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M17.745 21.757H6.255a5.005 5.005 0 0 1-5.005-5.004V7.255A5.005 5.005 0 0 1 6.255 2.25h11.49a5.005 5.005 0 0 1 5.005 5.005v9.488a5.005 5.005 0 0 1-5.005 5.014ZM6.255 3.741A3.513 3.513 0 0 0 2.74 7.255v9.488a3.513 3.513 0 0 0 3.514 3.513h11.49a3.513 3.513 0 0 0 3.514-3.514V7.255a3.513 3.513 0 0 0-3.514-3.514H6.255Zm7.937 7.948 3.293-2.793a.77.77 0 0 0 .09-1.06.76.76 0 0 0-1.06-.08l-3.294 2.792a1.912 1.912 0 0 1-2.462 0L7.376 7.745a.763.763 0 0 0-1.001 1.151l3.423 2.813a3.383 3.383 0 0 0 4.394 0v-.02Z"
    />
  </svg>
)
export const IconMail = memo(SvgComponent)
