import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M15.725 13.801h.396a6.298 6.298 0 0 0 6.28-6.307 2.66 2.66 0 0 0-1.936-2.545.687.687 0 0 0-.851.474.697.697 0 0 0 .474.861 1.258 1.258 0 0 1 .9 1.21 4.905 4.905 0 0 1-4.015 4.837 14.598 14.598 0 0 0 2.215-8.107 2.486 2.486 0 0 0-2.35-2.564h-9.25a2.496 2.496 0 0 0-2.36 2.564c-.055 2.89.75 5.732 2.312 8.165a4.905 4.905 0 0 1-4.508-4.837 1.248 1.248 0 0 1 .967-1.229.697.697 0 0 0-.329-1.345A2.641 2.641 0 0 0 1.6 7.494 6.298 6.298 0 0 0 7.88 13.8h.851c.436.39.924.716 1.451.968a.164.164 0 0 1 .087.193 19.533 19.533 0 0 1-2.012 5.031 1.567 1.567 0 0 0 0 1.567 1.51 1.51 0 0 0 1.325.774h5.244a1.548 1.548 0 0 0 1.354-.677 1.566 1.566 0 0 0 0-1.51 26.998 26.998 0 0 1-1.77-5.281.136.136 0 0 1 .068-.165c.45-.248.87-.55 1.248-.9Zm-.764 6.956c.027.059.047.12.057.184a.339.339 0 0 1-.193 0H9.581a.154.154 0 0 1-.135-.077.164.164 0 0 1 0-.174 21.122 21.122 0 0 0 2.148-5.39v-.057a1.626 1.626 0 0 0-.91-1.77 5 5 0 0 1-1.393-1.055c-1.644-1.76-2.728-4.876-2.728-8.194A1.103 1.103 0 0 1 7.59 3.063h9.248a1.093 1.093 0 0 1 .968 1.16c0 3.416-1.016 6.473-2.641 8.205-.38.386-.818.713-1.297.967a1.654 1.654 0 0 0-.764 1.8 28.79 28.79 0 0 0 1.857 5.562Z"
    />
  </svg>
)
export const IconCup = memo(SvgComponent)
