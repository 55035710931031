import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0066 16.8046C19.2953 15.2474 20.0698 13.2492 20.0698 11.07C20.0698 6.09944 16.0404 2.07001 11.0698 2.07001C6.09926 2.07001 2.06982 6.09944 2.06982 11.07C2.06982 16.0406 6.09926 20.07 11.0698 20.07C13.2491 20.07 15.2474 19.2955 16.8045 18.0067L20.4688 21.6709C20.8008 22.0029 21.3389 22.0029 21.6709 21.6709C22.0028 21.339 22.0028 20.8008 21.6709 20.4689L18.0066 16.8046ZM11.0698 18.402C7.02049 18.402 3.73786 15.1193 3.73786 11.07C3.73786 7.02068 7.02049 3.73804 11.0698 3.73804C15.1192 3.73804 18.4018 7.02068 18.4018 11.07C18.4018 15.1193 15.1192 18.402 11.0698 18.402Z"
      fill="currentColor"
    />
  </svg>
)
export const IconMagnifier = memo(SvgComponent)
