import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M18.961 12.871H5.04a.87.87 0 0 1 0-1.74H18.96a.87.87 0 0 1 0 1.74Zm3.539 7.031a.87.87 0 0 0-.87-.87H2.37a.87.87 0 0 0 0 1.74h19.26a.881.881 0 0 0 .87-.87Zm0-15.802a.882.882 0 0 0-.87-.87H2.37a.87.87 0 1 0 0 1.74h19.26a.87.87 0 0 0 .87-.87Z"
    />
  </svg>
)
export const IconMenu = memo(SvgComponent)
